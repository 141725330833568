<template>
  <div>
      <global-table :addButton="'Add Access Menu'" :fields="fields"  :table-data="getData" :total-rows="totalRows" :loading="isLoading"  :redirect-detail="false" :showAddButton="admin == 1 ? true : false" :removeButton="admin == 1 ? true : false" @addForm="addForm"  @editForm="editForm" @removeData="deleteData"></global-table>


    <b-modal ref="modalForm" id="addModals" header-border-variant="no-border text-center" footer-border-variant="no-border" footer-bg-variant="light" :no-close-on-backdrop="true" :no-close-on-esc="true" size="xl">
      <div slot="modal-header" class="text-center">
        <h3 class="text-center">{{ isEdit == true ? "Edit" : "Add" }} Access Menu</h3>
      </div>

      <validation-observer v-if="!isLoading" ref="Accessmenu" tag="form" @submit.prevent="submitAdd">
        <b-form>
          <b-row>
            <b-col xl="3" col-md-4>
              <b-form-group label="Nik">
                <validation-provider #default="{ errors }" name="Nik" rules="required">
                  <model-select :options="dropdown_nik" v-model="formData.nik" placeholder="-- Please select --" :state="errors.length > 0 ? false : null"></model-select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col xl="3" col-md-4>
              <b-form-group label="Admin">
                <validation-provider #default="{ errors }" name="Admin" rules="">
                  <model-select @input="change_admin" :options="dropdown_admin" v-model="formData.admin" placeholder="-- Please select --" :state="errors.length > 0 ? false : null"></model-select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col xl="3" col-md-4>
              <b-form-group label="View Only">
                <validation-provider #default="{ errors }" name="View Only" rules="">
                  <model-select @input="change_view_only" :options="dropdown_view_only" v-model="formData.view_only" placeholder="-- Please select --" :state="errors.length > 0 ? false : null"></model-select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col xl="3" col-md-4>
              <b-form-group label="Redirect">
                <validation-provider #default="{ errors }" name="Redirect" rules="required">
                  <model-select v-model="formData.welcome_redirect" :options="dropdown_redirect" placeholder="-- Please select --" label="text" track-by="value" :state="errors.length > 0 ? false : null"></model-select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col xl="12" col-md-4>
              <b-form-group label="Menu" label-for="Menu">
                <validation-provider #default="{ errors }" name="Menu" rules="required">
                  <multiselect v-model="selected_menu" :options="dropdown_menu" :multiple="true" :close-on-select="true" :clear-on-select="true" placeholder="Pick some" label="text" track-by="value" :state="errors.length > 0 ? false : null">
                  </multiselect>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
      <loading v-if="isLoading"></loading>

      <div slot="modal-footer" class="float-right">
        <b-button type="button" size="sm" variant="danger" @click="cancelAdd"><i class="fa fa-undo"></i> Back</b-button>
        &nbsp;
        <b-button v-if="admin == 1" type="button" size="sm" variant="primary" @click="submitAdd"><i class="fa fa-save"></i> Save</b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import {
  ValidationProvider,
  ValidationObserver,
} from "vee-validate/dist/vee-validate.full";
import axios from "axios";
import interfaces from "@/templates/_interfaces.js";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import Loading from "@core/loading/Loading.vue";
import GlobalTable from "@/templates/GlobalTableNew.vue";
import Multiselect from "vue-multiselect";

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    ToastificationContent,
    Loading,
    GlobalTable,
    Multiselect,
  },
  data() {
    return {
      admin : localStorage.getItem("admin"),
      totalRows: 1,
      isEdit: false,
      isLoading: false,
      dropdown_nik: [],
      dropdown_menu: [],
      dropdown_redirect: [],
      dropdown_area: [],
      selected_menu: [],
      selected_area: [],
      defaultValueTag: [],
      formData: interfaces.masterAccessMenu,
      fields: [
        { key: "nik", label: "Nik" },
        { key: "access_menu_employee_name", label: "Employee Name" },
        { key: "menu_name", label: "Menu" },
        { key: "welcome_redirect", label: "Redirect to" },
        { key: "action", label: "Action", class: "text-center width-80" },
      ],
      dropdown_admin: [
        { text: "Admin", value: "1" },
      ],
      dropdown_view_only: [
        { text: "View Only", value: "1" },
      ],
    };
  },
  methods: {
    init: function () {
      this.isEdit = false;
      this.isLoading = false;
      this.get_nik();
      this.get_menu();
    },
    getData: function (ctx) {
      let response = axios.post("master/access-menu/list", ctx);
      return response.then((response) => {
        const items = response.data.data;
        this.totalRows = response.data.total;
        return items;
      }).catch((error) => {
        return [];
      });
    },
    get_nik: function () {
      axios.get("dropdown/nik").then((response) => {
        this.dropdown_nik = response.data.data;
      }).catch((error) => {}).finally(() => {});
    },
    get_menu: function () {
      axios.get("dropdown/menu").then((response) => {
        this.dropdown_menu = response.data.data;
        this.dropdown_redirect = response.data.redirect;
        this.dropdown_area = response.data.area;
      }).catch((error) => {}).finally(() => {});
    },
    change_admin: function () {
      if (this.formData.admin == 1) {
        this.formData.view_only = null;
      }
    },
    change_view_only: function () {
      if (this.formData.view_only == 1) {
        this.formData.admin = null;
      }
    },
    cancelAdd: function () {
      this.resetForm();
      this.selected_area = null;
      this.selected_menu = null;
      this.formData.menu = null;
      this.$refs.modalForm.hide();
    },
    addForm: function () {
      this.resetForm();
      this.selected_area = null;
      this.selected_menu = null;
      this.formData.menu = null;
    },
    editForm: function (a) {
      this.isEdit = true;
      axios.get("dropdown/menu-list/" + a.id).then((response) => {
        this.formData = a;
        this.selected_menu = response.data.data;
        this.selected_area = response.data.selected_area;
      });
      this.formData.menu = this.temp;
      this.$refs.modalForm.show();
    },
    deleteData: function (a) {
      if (confirm("Are you sure want to Delete this data ?")) {
        this.isLoading = true;
        axios.post("master/access-menu/delete",a).then((response) => {
          if(response.data.status == 2000){
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Form Delete successful",
                icon: "EditIcon",
                variant: "success",
                text: response.data.message,
              },
            });
          }else{
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Form Delete unsuccessful",
                icon: "EditIcon",
                variant: "warning",
                text: response.data.message,
              },
            });
          }
        }).catch((error) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Form Delete Failed",
              icon: "EditIcon",
              variant: "danger",
              text: error.message,
            },
          });
        }).finally(() => {
          this.isLoading = false;
        });
      }
    },
    resetForm: function () {
      this.isEdit = false;
      for (var i in interfaces.masterAccessMenu) {
        interfaces.masterAccessMenu[i] = null;
      }
      this.formData = interfaces.masterAccessMenu;
      this.selected_menu = null;
      this.selected_area = null;
    },
    submitAdd: function () {
      this.$refs.Accessmenu.validate().then((success) => {
        if (success) {
          if (this.isEdit == true) {
            this.formData.selected_area = this.selected_area 
            this.formData.selected_menu = this.selected_menu
            if (confirm("Are you sure want to Update this data ?")) {
              this.isLoading = true;
              axios.put("master/access-menu/edit", this.formData).then((response) => {
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: "Update Form Successful",
                      icon: "EditIcon",
                      variant: "success",
                      text: response.data.message,
                    },
                  });
                })
                .catch((error) => {
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: "Update Form Failed",
                      icon: "EditIcon",
                      variant: "danger",
                      text: error.message,
                    },
                  });
                })
                .finally(() => {
                  this.isLoading = false;
                  this.$refs.modalForm.hide();
                  this.$emit("reloadData", this.$route.params.id);
                  this.init();
                  this.resetForm();
                  this.selected_area = null;
                  this.selected_menu = null;
                  this.formData.menu = null;
                });
            }
          } else {
            this.formData.selected_area = this.selected_area
            this.formData.selected_menu = this.selected_menu
            if (confirm("Are you sure want to Add this data ?")) {
              this.isLoading = true;
              axios.post("master/access-menu/add", this.formData).then((response) => {
                if(response.data.status == 2000){
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: "Form Add successful",
                      icon: "EditIcon",
                      variant: "success",
                      text: response.data.message,
                    },
                  });
                }else{
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: "Form Add unsuccessful",
                      icon: "EditIcon",
                      variant: "warning",
                      text: response.data.message,
                    },
                  });
                }
              }).catch((error) => {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: "Form Add Failed",
                    icon: "EditIcon",
                    variant: "danger",
                    text: error.message,
                  },
                });
              }).finally(() => {
                this.isLoading = false;
                this.$refs.modalForm.hide();
                this.init();
                this.resetForm();
                this.selected_area = null;
                this.selected_menu = null;
                this.formData.menu = null;
              });
            }
          }
        }
      });
    },
  },
  mounted() {
    this.init();
  },
};
</script>
