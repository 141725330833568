var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('global-table',{attrs:{"addButton":'Add Access Menu',"fields":_vm.fields,"table-data":_vm.getData,"total-rows":_vm.totalRows,"loading":_vm.isLoading,"redirect-detail":false,"showAddButton":_vm.admin == 1 ? true : false,"removeButton":_vm.admin == 1 ? true : false},on:{"addForm":_vm.addForm,"editForm":_vm.editForm,"removeData":_vm.deleteData}}),_c('b-modal',{ref:"modalForm",attrs:{"id":"addModals","header-border-variant":"no-border text-center","footer-border-variant":"no-border","footer-bg-variant":"light","no-close-on-backdrop":true,"no-close-on-esc":true,"size":"xl"}},[_c('div',{staticClass:"text-center",attrs:{"slot":"modal-header"},slot:"modal-header"},[_c('h3',{staticClass:"text-center"},[_vm._v(_vm._s(_vm.isEdit == true ? "Edit" : "Add")+" Access Menu")])]),(!_vm.isLoading)?_c('validation-observer',{ref:"Accessmenu",attrs:{"tag":"form"},on:{"submit":function($event){$event.preventDefault();return _vm.submitAdd.apply(null, arguments)}}},[_c('b-form',[_c('b-row',[_c('b-col',{attrs:{"xl":"3","col-md-4":""}},[_c('b-form-group',{attrs:{"label":"Nik"}},[_c('validation-provider',{attrs:{"name":"Nik","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('model-select',{attrs:{"options":_vm.dropdown_nik,"placeholder":"-- Please select --","state":errors.length > 0 ? false : null},model:{value:(_vm.formData.nik),callback:function ($$v) {_vm.$set(_vm.formData, "nik", $$v)},expression:"formData.nik"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,915865623)})],1)],1),_c('b-col',{attrs:{"xl":"3","col-md-4":""}},[_c('b-form-group',{attrs:{"label":"Admin"}},[_c('validation-provider',{attrs:{"name":"Admin","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('model-select',{attrs:{"options":_vm.dropdown_admin,"placeholder":"-- Please select --","state":errors.length > 0 ? false : null},on:{"input":_vm.change_admin},model:{value:(_vm.formData.admin),callback:function ($$v) {_vm.$set(_vm.formData, "admin", $$v)},expression:"formData.admin"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2122867484)})],1)],1),_c('b-col',{attrs:{"xl":"3","col-md-4":""}},[_c('b-form-group',{attrs:{"label":"View Only"}},[_c('validation-provider',{attrs:{"name":"View Only","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('model-select',{attrs:{"options":_vm.dropdown_view_only,"placeholder":"-- Please select --","state":errors.length > 0 ? false : null},on:{"input":_vm.change_view_only},model:{value:(_vm.formData.view_only),callback:function ($$v) {_vm.$set(_vm.formData, "view_only", $$v)},expression:"formData.view_only"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1962411797)})],1)],1),_c('b-col',{attrs:{"xl":"3","col-md-4":""}},[_c('b-form-group',{attrs:{"label":"Redirect"}},[_c('validation-provider',{attrs:{"name":"Redirect","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('model-select',{attrs:{"options":_vm.dropdown_redirect,"placeholder":"-- Please select --","label":"text","track-by":"value","state":errors.length > 0 ? false : null},model:{value:(_vm.formData.welcome_redirect),callback:function ($$v) {_vm.$set(_vm.formData, "welcome_redirect", $$v)},expression:"formData.welcome_redirect"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2669052635)})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"xl":"12","col-md-4":""}},[_c('b-form-group',{attrs:{"label":"Menu","label-for":"Menu"}},[_c('validation-provider',{attrs:{"name":"Menu","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('multiselect',{attrs:{"options":_vm.dropdown_menu,"multiple":true,"close-on-select":true,"clear-on-select":true,"placeholder":"Pick some","label":"text","track-by":"value","state":errors.length > 0 ? false : null},model:{value:(_vm.selected_menu),callback:function ($$v) {_vm.selected_menu=$$v},expression:"selected_menu"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,4176849699)})],1)],1)],1)],1)],1):_vm._e(),(_vm.isLoading)?_c('loading'):_vm._e(),_c('div',{staticClass:"float-right",attrs:{"slot":"modal-footer"},slot:"modal-footer"},[_c('b-button',{attrs:{"type":"button","size":"sm","variant":"danger"},on:{"click":_vm.cancelAdd}},[_c('i',{staticClass:"fa fa-undo"}),_vm._v(" Back")]),_vm._v("   "),(_vm.admin == 1)?_c('b-button',{attrs:{"type":"button","size":"sm","variant":"primary"},on:{"click":_vm.submitAdd}},[_c('i',{staticClass:"fa fa-save"}),_vm._v(" Save")]):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }